import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl, sanityFileUrl } from '../../../utils/format'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

SwiperCore.use([Navigation])

export default ({ athletes }) => {
  if (!athletes || athletes.isHide) return null

  const buildSliderSettings = () => {
    return {
      spaceBetween: 64,
      slidesPerView: 4,
      simulateTouch: true,
      navigation: {
        nextEl: `.swiper-btn-next`,
        prevEl: `.swiper-btn-prev`,
        disabledClass: `${styles.btnDisabled}`,
      },
      className: styles.slider,
      breakpoints: {
        0: {
          slidesPerView: 1.3,
          spaceBetween: 16,
        },
        701: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        901: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
      },
    }
  }

  return (
    <div className={styles.athletes}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: athletes.title }} />
        <div className={styles.sliders}>
          <div className={styles.line}>
            <Swiper {...buildSliderSettings()}>
              {athletes.items &&
                athletes.items.map(item => (
                  <SwiperSlide className={styles.item} key={item._key}>
                    <div className={styles.info}>
                      <img
                        src={sanityImageUrl(item.image)}
                        alt={item.image?.caption}
                        className={styles.image}
                        data-id={item._key}
                      />
                      <div className={styles.sign}>
                        {item.signImage?.asset && (
                          <img
                            className={styles.signImage}
                            {...srcSetProps(sanityImageUrl(item.signImage))}
                            alt={item.signImage?.caption}
                          />
                        )}
                        <p className={styles.signName}>{item.name}</p>
                        <p className={styles.signInfo}>{item.info}</p>
                        {item.buttonText && item.buttonUrl && (
                          <Link to={item.buttonUrl} className={styles.link}>
                            {item.buttonText}
                          </Link>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className={cx(styles.btnPrev, `swiper-btn-prev`)} />
            <div className={cx(styles.btnNext, `swiper-btn-next`)} />
          </div>
        </div>
      </div>
    </div>
  )
}
